<template>
    <v-app style="background: transparent" :class="{}">
        <!-- begin:: Content Head -->
        <KTSubheader
                v-if="nav.subheaderDisplay"
                v-bind:breadcrumbs="nav.breadcrumbs"
                v-bind:title="nav.pageTitle"
        />
        <!-- end:: Content Head -->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">

                <form class="form fv-plugins-bootstrap fv-plugins-framework" id="dataForm" novalidate="novalidate">

                    <div class="card card-custom example example-compact">
                        <div class="card-header">
                            <h3 class="card-title">采购信息</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row ">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">采购类型 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <b-form-select class="form-control" v-model="purchasement.channel" :options="channel_list" name="channel" disabled="disabled">

                                    </b-form-select>
                                </div>
                            </div>

                            <div class="form-group row " v-if="purchasement.channel == 1 || purchasement.channel == 2">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">采购仓库 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <b-form-select class="form-control" v-model="purchasement.warehouse_id" :options="warehouse_list" name="warehouse_id">

                                    </b-form-select>
                                </div>
                            </div>

                            <div class="form-group row ">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">备注:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                <textarea class="form-control" v-model="purchasement.note"
                                          name="SEO_desc" rows="5">
                                </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Card-->




                    <div class="card card-custom example example-compact mt-10">
                        <div class="card-header">
                            <h3 class="card-title">产品信息</h3>
                        </div>
                        <div class="card-body">


                            <div class="form-group table-responsive">
                                <table class="table ">
                                    <thead>
                                    <tr class="alert alert-light">
                                        <th scope="col">产品信息</th>
                                        <th scope="col" width="10%">价格</th>
                                        <th scope="col" width="10%">数量</th>
                                        <th scope="col" width="10%">金额</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <template v-for="(item, idx) in details">
                                        <tr :key="idx" v-if="checkAddProduct(item.product_id)">
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="symbol symbol-60 flex-shrink-0">
                                                        <div class="symbol-label"
                                                             :style="'background-image:url('+item.image_url+')'"></div>
                                                    </div>
                                                    <div class="ml-2">
                                                        <div class="text-dark-75 font-weight-bold line-height">
                                                            {{item.product_title}}
                                                        </div>
                                                        <div class="text-dark-75 line-height">
                                                            {{item.sku}}
                                                        </div>
                                                        <div class="font-size-sm text-dark-50 text-hover-primary">
                                                            <template v-for="attr in JSON.parse(item.attr_info)">
                                                                <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>


                                            <td><input type="text" @input="onFloatInput" name="price"  v-model="details[idx].price" class="form-control" placeholder=""></td>
                                            <td><input type="text" @input="onIntInput" name="quantity"  v-model="details[idx].quantity" class="form-control" placeholder=""></td>
                                            <td><input type="text" disabled="disabled" @input="onIntInput" name="amount"  v-model="details[idx].amount" class="form-control" placeholder=""></td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>



                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">运费:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           @input="onFloatInput"
                                           name="shipping_amount"
                                           v-model="purchasement.shipping_amount">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">其他费用:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           @input="onFloatInput"
                                           name="other_amount"
                                           v-model="purchasement.other_amount">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">总费用:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           @input="onFloatInput"
                                           name="amount"
                                           v-model="purchasement.amount">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Card-->

                    <div class="card card-custom example example-compact mt-10">
                        <div class="card-header">
                            <h3 class="card-title">供应商信息</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group row ">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">供应商 <span class="text-danger">*</span>:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <b-form-select class="form-control" v-model="purchasement.supplier_id" :options="supplier_list" name="supplier_id">

                                    </b-form-select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">联系方式:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="email_skype"
                                           v-model="purchasement.phone"
                                           disabled="disabled">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">收款人:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="supplier_payee"
                                           v-model="purchasement.supplier_payee">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">支付方式:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="supplier_channel"
                                           v-model="purchasement.supplier_channel">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-form-label col-lg-3 col-sm-12 text-right">收款账号:</label>
                                <div class="col-lg-4 col-md-9 col-sm-12">
                                    <input class="form-control tt-input"
                                           type="text"
                                           name="supplier_account"
                                           v-model="purchasement.supplier_account">
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-lg-9 ml-lg-auto">
                                    <button ref="data_form_submit" type="submit" class="btn btn-primary mr-2"> 保存</button>
                                    <button type="reset" class="btn btn-light-primary">关闭</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>

        <v-dialog v-model="dialog.show" persistent max-width="1200px">
            <component
                    :key="dialog.id"
                    :title="dialog.title"
                    :visible="dialog.show"
                    :is="dialog.view"
                    :dialog_data="dialog.data"
                    @opeareResultHandler="opeareResultHandler"></component>
        </v-dialog>

    </v-app>
</template>

<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTUtil from "@/assets/js/components/util";
    import TEditor from '@/view/content/tinymce/Index'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Treeselect from '@riophae/vue-treeselect'
    import matchAliProduct from '@/view/pages/purchasement/MatchAliProduct';

    import {getSupplier, getProduct, getWareHouse, getProductSku, addPurchasement, getAliProduct} from '@/api/purchasement';
    import {getOrder} from '@/api/order';

    export default {
        name: "add_order_purchasement_view",
        components: {
            KTSubheader,
            TEditor,
            Treeselect
        },
        created: function(){
            var _this = this;

            //订单信息
            getOrder({'order_id': this.$route.query.order_id}).then(function(res) {
                if(res && res.response) {
                    _this.details = res.response.details;
                }
            });
            getSupplier().then(function(res) {
                if(res && res.response) {
                    _this.supplier_cache = res.response;
                    _this.supplier_list = KTUtil.listToSelectOptions(res.response, 'id', 'name');
                }
            });
            getWareHouse().then(function(res) {
                if(res && res.response) {
                    _this.warehouse_list = KTUtil.listToSelectOptions(res.response, 'id', 'name');
                }
            });

        },
        watch: {
            'purchasement.supplier_id': function(val){
                var _this = this;
                if(_this.purchasement.supplier_id && _this.supplier_cache && _this.supplier_cache.length > 0){
                    _this.supplier_cache.forEach(function(value){
                        if(value['id'] == _this.purchasement.supplier_id){
                            _this.purchasement.phone = value.phone;
                            _this.purchasement.supplier_channel = value.channel;
                            _this.purchasement.supplier_account = value.account;
                            _this.purchasement.supplier_payee = value.payee;
                            _this.purchasement.supplier_name = value.name;
                            return;
                        }
                    });
                } else {
                    _this.purchasement.phone = '';
                    _this.purchasement.supplier_channel = '';
                    _this.purchasement.supplier_account = '';
                    _this.purchasement.supplier_payee = '';
                    _this.purchasement.supplier_name = '';
                }
            },
            'details': {
                handler (val, oldval) {
                    this.calculateAmount();
                },
                deep: true
            },
            'purchasement.shipping_amount': function(val){
                this.calculateAmount();
            },
            'purchasement.other_amount': function(val){
                this.calculateAmount();
            }

        },
        data() {
            return {
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/purchasement', 'title':'采购管理'}, {'id':'', 'route':'/purchasement/rootpurchasement', 'title':'一键采购'}],
                    pageTitle: '仓库'
                },
                channel_list: [{'value': 1, 'text': '手工采购'}, {'value': 2, 'text': '1688采购'}, {'value': 3, 'text': '其他采购'}],
                supplier_list: [],
                supplier_cache: [],
                warehouse_list: [],
                product_ids: this.$route.query.product_ids,
                details: [],
                purchasement: {
                    channel: 1,
                    warehouse_id: this.$route.query.warehouse_id,
                    supplier_id: '',
                    phone: '',
                    supplier_name: '',
                    supplier_channel: '',
                    supplier_account: '',
                    supplier_payee: '',
                    product_id: null,
                    product_title: '',
                    details: [],
                    shipping_amount: '',
                    other_amount: '',
                    amount: '',
                    note:'',
                    ali_product: null
                },
                dialog: {
                    id: 0,
                    show: false,
                    title: '',
                    view: null,
                    data: null
                }

            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    channel: {
                        validators: {
                            notEmpty: {
                                message: "采购类型不能为空"
                            }
                        }
                    },

                    supplier_id: {
                        validators: {
                            notEmpty: {
                                message: "采购供应商不能为空"
                            }
                        }
                    },
                    warehouse_id: {
                        validators: {
                            notEmpty: {
                                message: "采购仓库不能为空"
                            }
                        }
                    },

                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                //批量保存
                _this.product_ids.forEach(function(val, idx) {

                    if(_this.checkAddProduct(val)){

                        var data = {
                            channel: 1,
                            warehouse_id: _this.purchasement.warehouse_id,
                            supplier_id: _this.purchasement.supplier_id,
                            phone: _this.purchasement.phone,
                            supplier_name: _this.purchasement.supplier_name,
                            supplier_channel: _this.purchasement.supplier_channel,
                            supplier_account: _this.purchasement.supplier_account,
                            supplier_payee: _this.purchasement.supplier_payee,
                            shipping_amount: _this.purchasement.shipping_amount,
                            other_amount: _this.purchasement.other_amount,
                            note: _this.purchasement.note,
                        };
                        data.product_id = val;
                        data.details = [];
                        _this.details.forEach(function(d, i){
                            if(d.product_id == val) {
                                d.sku_name = d.sku;
                                d.id = d.product_sku_id;
                                data.details.push(d);
                                data.product_title = d.product_title;
                            }

                        });

                        //计算总金额
                        var amount = 0;
                        data.details.forEach(function(value, index){
                            var sum = value.quantity * value.price;
                            if(sum) {
                                amount += sum;
                                data['details'][index].amount = sum;
                            }
                        });
                        if(data.shipping_amount) {
                            amount += parseFloat(data.shipping_amount);
                        }
                        if(data.other_amount) {
                            amount += parseFloat(data.other_amount);
                        }
                        data.amount = amount?amount:'';




                        addPurchasement(data).then(function (res){
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                                data_form.reset();
                                _this.$router.push({path: '/purchasement'});
                            }
                            submitButton.classList.remove(
                                "spinner",
                                "spinner-light",
                                "spinner-right"
                            );
                        });
                    }

                });

            });
        },
        methods: {

            opeareResultHandler(result) {
                if(result){

                }
                this.dialog.show = false;
            },
            onFloatInput(e){
                e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
            },
            onIntInput(e){

                e.target.value = parseInt(e.target.value) || null;
            },
            loadOptions({ action, searchQuery, callback }) {
                if (action === 'ASYNC_SEARCH') {
                    getProduct({'product_title': searchQuery}).then(function (res) {
                        if(res && res.response){
                            var options = [];
                            res.response.forEach(function(value){
                                options.push({'id': value.id, 'label': value.site_name +'  '+ value.product_title});
                            });
                            callback(null, options);
                        }
                    });
                }

            },
            calculateAmount() {
                var _this = this;
                var amount = 0;
                _this.details.forEach(function(value, index){
                    var sum = value.quantity * value.price;
                    if(sum) {
                        amount += sum;
                        _this.details[index].amount = sum;
                    }
                });
                if(_this.purchasement.shipping_amount) {
                    amount += parseFloat(_this.purchasement.shipping_amount);
                }
                if(_this.purchasement.other_amount) {
                    amount += parseFloat(_this.purchasement.other_amount);
                }
                _this.purchasement.amount = amount?amount:'';
            },
            selectProduct(val){
                this.purchasement.product_title = val.label;
            },
            checkAddProduct(product_id) {
                if(product_id == this.product_ids){
                    return true;
                } else {
                    for(var i = 0;i < this.product_ids.length;i++) {
                        if(this.product_ids[i] == product_id){
                            return true;
                        }
                    }
                }
                return false;
            }
        }
    }
</script>
